import { FC, createRef, memo } from 'react';
import styles from '../../../ExpensionMap.module.scss';
import cx from 'classnames';
import { useExpansionMapHelper } from '../../../helper';
import { Districts } from '../../../../../constants/districts';

export const Hoehenberg: FC = memo(() => {
  const ref = createRef<SVGTextElement>();
  const {
    district,
    expansionStatusClass,
    isSelected,
    handleClick,
    handleHover,
  } = useExpansionMapHelper({
    district: Districts.Hoehenberg,
    ref,
  });

  return (
    <>
      <g
        className={cx(styles.districtWrapper, {
          [styles.isSelected]: isSelected,
        })}
        id={district}
        onClick={handleClick}
        onMouseEnter={handleHover}
      >
        <path
          className={cx(styles[expansionStatusClass], styles.districtArea)}
          aria-label="Höhenberg"
          d="M752,606l6,14l-18,10l-4,10c-16.3,6.3-14.3,6.4-32,6v6c-1,0-10.4,0-11,0l1-32l8-2l-7.3-18.3 c17.8-1.1,37-6.1,52.8-19.5l0.5,1.8L752,606z"
        />
        <text
          className={styles.districtName}
          ref={ref}
          transform="matrix(1 0 0 1 706.3001 617.2661)"
        >
          <tspan x="0" y="0">
            Höhen-
          </tspan>
          <tspan x="0" y="14.4">
            berg
          </tspan>
        </text>
      </g>
    </>
  );
});
